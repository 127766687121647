/**
 * index.js
 */

'use strict';

var OAT = {};

OAT = {

  init : function(){

    OAT.os = OAT.checkDevice();

    OAT.ww = $(window).width();
    OAT.wh = $(window).height();
    OAT.st = 0;

    OAT.mainimg = '/assets/img/mainimg/'+ OAT.randMainimg();
    $("<img>").attr("src", OAT.mainimg).on('load',function() {
        OAT.animateMainimg();
    });

    OAT.intLang();
    OAT.initMainimg();

    $(function(){
      OAT.gNavi.initNav();
      OAT.bind();
      OAT.smoothScroll();
    });

    $(window)
    .on('load', function() {
      OAT.settingCarousel();
    })
    .on('scroll touchmove', function(e) {
      OAT.pagetop._onTouchMove(e);

      // fix issue #24
      if(OAT.os == 'iphone') {
        var wh = $(window).height();
        var scrollTop = $(window).scrollTop() ? $(window).scrollTop() : 0;
        $('.lang-form').css('top',scrollTop+(wh/2)+'px');
      }
    });

  },

  bind : function() {
    OAT.gNavi.bindNav();
    OAT.changeLang();
  },

  //
  // 言語選択
  //
  intLang : function() {

    // fix issue #24
    if(OAT.os == 'iphone') {
      $('.l-header').after($('.lang-form').clone().addClass('is-ios'));
      $('.l-header .lang-form').remove();
    }

    var _lang = location.href.split("/");
    $('.lang-form select option:selected').removeAttr('selected');
    var _lang_label = $('.lang-form select option.'+'lang_'+_lang[_lang.length -2]).text();
    if(_lang_label !== '') {
      $('.lang-form select option.'+'lang_'+_lang[_lang.length -2]).attr('selected','selected');
      $('.lang-display').text(_lang_label);
    } else {
      $('.lang-form select option.lang_en').attr('selected','selected');
      $('.lang-display').text($('.lang-form select option.lang_en').text());
    }
  },

  changeLang : function() {

    // fix issue #24
    $('.l-lang').on('click',function() {
      if(OAT.os == 'iphone') $('.lang-form select').focus();
    });

    // OAT.iphoneChangeLangFlg = false;
    $('.lang-form select')
      // .on('focus',function() {
      //   console.log('focus!!');
      // })
      // // fix issue #24
      // .on('touchstart', function(e) {
      //   if(OAT.os == 'iphone' && !OAT.iphoneChangeLangFlg) {
      //     OAT.st = $(window).scrollTop();
      //     // console.log('touchstart');
      //     $('.l-wrapper')
      //       .addClass('is-stuck')
      //       .scrollTop(OAT.st);
      //   }
      // })
      // // .on('focus', function(e) {
      // //   if(OAT.os == 'iphone') {
      // //     OAT.st = $(window).scrollTop();
      // // console.log('focus');
      // //     $('.l-wrapper')
      // //       .addClass('is-stuck')
      // //       .stop()
      // //       .scrollTop($('.l-wrapper').scrollTop() + OAT.st);
      // //   }
      // // })
      // .on('blur', function(e) {
      //   if(OAT.os == 'iphone') {
      //     // console.log('blur');
      //     $('.l-wrapper').removeClass('is-stuck');
      //     $('body').scrollTop(OAT.st);
      //   }
      // })
      .on('change', function() {
        var val = $('option:selected',this).val();
        val = val=='en' ? '/' : '/'+val+'/';
        location.href = val;
      });
  },

  //
  // メインイメージ
  //
  initMainimg : function() {
    OAT.header = $('.l-header');
    OAT.header.css({'background-image':'url('+ OAT.mainimg +')' });
    OAT.header.css('opacity',0);
    // setting mainimg
    OAT.header.css('height',OAT.wh+"px");
    OAT.header.find('.header-hero').css('top',(OAT.wh-339)/2+5+'px');
  },
  randMainimg : function() {
    var mainimgs = [
      'pic01.jpg',
      'pic02.jpg',
      'pic03.jpg',
      'pic04.jpg',
      'pic05.jpg',
      'pic06.jpg',
      // 'pic07.jpg',
      // 'pic08.jpg' ,
    ];
    var randImg = mainimgs[Math.floor(Math.random() * mainimgs.length)];
    return randImg;
  },
  animateMainimg : function(){

    OAT.header.animate({'opacity':1},300);
    setTimeout( function() { OAT.header.find('.header-logo_camera').animate({'opacity':1},300); }, 1000 );

    setTimeout( function() { OAT.header.animate({'opacity':0},300); }, 1800 );
    setTimeout( function() { OAT.header.animate({'opacity':1},1000); }, 2200 );

    setTimeout( function() { OAT.header.find('.header-logo_title, .header-logo_subtitle, .gNavi-trigger, .l-lang').animate({'opacity':1},1000); }, 3500 );
  },

  //
  // カルーセル
  //
  settingCarousel : function() {
    // setting carousel
    $('.l-carousel').each( function() {
      var len = $('img',this).length;
      $('img',this).css('width',OAT.ww-30+'px');
      $('ul',this).css('width',(OAT.ww-30)*len+'px');
    });
  },

  //
  // smooth scroll
  //
  smoothScroll : function() {
    $('a[href^="#"]').not('.l-gNavi ul li a').on('click',function() {
      var target = $(this.hash);
      OAT._smoothScroll(target);
      return false;
    });
  },
  _smoothScroll : function(target) {
    var offsetY = 0;
    var time = 500;
    if (!target.length) return ;
    var targetY = target.offset().top+offsetY;
    $('html,body').animate({scrollTop: targetY}, time, 'swing');
    return false;
  },
  

  //
  // check device
  //
  checkDevice : function(){
    var u = window.navigator.userAgent.toLowerCase();
    if(u.indexOf("iphone") != -1) { return 'iphone' }
    else { return null }
    // var _ua = (function(u){
      // return {
      //   Tablet:(u.indexOf("windows") != -1 && u.indexOf("touch") != -1 && u.indexOf("tablet pc") == -1)
      //     || u.indexOf("ipad") != -1
      //     || (u.indexOf("android") != -1 && u.indexOf("mobile") == -1)
      //     || (u.indexOf("firefox") != -1 && u.indexOf("tablet") != -1)
      //     || u.indexOf("kindle") != -1
      //     || u.indexOf("silk") != -1
      //     || u.indexOf("playbook") != -1,
      //   Mobile:(u.indexOf("windows") != -1 && u.indexOf("phone") != -1)
      //     || u.indexOf("iphone") != -1
      //     || u.indexOf("ipod") != -1
      //     || (u.indexOf("android") != -1 && u.indexOf("mobile") != -1)
      //     || (u.indexOf("firefox") != -1 && u.indexOf("mobile") != -1)
      //     || u.indexOf("blackberry") != -1
      // }
    // })(window.navigator.userAgent.toLowerCase());
    // if(_ua.Tablet){
    //   return 'tablet'
    // } else if(_ua.Mobile){
    //   return 'sp';
    // } else {
    //   return 'pc';
    // }
  },


}

OAT.init();

//
//  _ 晴れの国 _________________,,
// |                            |
// |   O K A Y A M A !!!!!!!!   |
// |____________________________|
//            ||
//            (V)∧_∧(V)
//            ヽ(･ω･)ﾉ イイトコロダナー
//              / /
//.wwwwwwwwww.ノ￣ゝ.wwwwwwwwwwwwwwwwwwwwwwwwww
//

OAT.gNavi = {

  scrolltop : 0,

  //
  // navi
  //
  initNav : function() {
    $('.l-gNavi').hide();
  },

  bindNav : function() {
    $('.gNavi-trigger a').on('click',function() {
      if ($(this).hasClass('is-open')) {
        OAT.gNavi.closeNav();
        OAT.gNavi.allowScroll(OAT.gNavi.scrolltop);
        $(this).removeClass('is-open');
      } else {
        OAT.gNavi.scrolltop = $(window).scrollTop();
        OAT.gNavi.openNav();
        OAT.gNavi.stopScroll(OAT.gNavi.scrolltop);
        $(this).addClass('is-open');
      }
    });
    $('.l-gNavi ul li a').click(function() {
        OAT.gNavi.closeNav();
        OAT.gNavi.allowScroll(OAT.gNavi.scrolltop);
        $('.gNavi-trigger a').removeClass('is-open');
        var target = $(this.hash);
        setTimeout(function() {
          OAT._smoothScroll(target);
        },300);
        return false;
    });
  },

  openNav : function() {
    $('.l-gNavi').show();
    setTimeout(function(){
      $('.l-gNavi').addClass('is-open');
    },100);
    setTimeout(function() {
      $('.l-gNavi ul li').animate({"margin-left": 0}, 100, "easeOutQuint");
    }, 150);
  },
  closeNav : function() {
    $('.l-gNavi ul li').animate({"margin-left": '-100%'}, 100, "easeOutQuint");
    setTimeout(function(){
      $('.l-gNavi').removeClass('is-open');
    },100);
    setTimeout(function(){
      $('.l-gNavi').hide();
    },300);
  },

  // スクロール禁止
  stopScroll : function(scrolltop) {
    // $('body,html').css({'overflow':'hidden'});
    $('body').addClass('fixed').css({'top': - scrolltop});
    // $(window).on('touchmove.noScroll', function(e) {
    //   e.preventDefault();
    // });
  },

  // スクロール許可
  allowScroll : function(scrolltop) {
    // $('body,html').css({'overflow':'visible'});
    $('body').removeClass('fixed').css({'top': 0});
    $(window).scrollTop(scrolltop);
    // $(window).off('.noScroll');
  },


}
OAT.map = {

  mapID : 'gmap',
  map : null,
  marker : [],
  infoWindow : [],
  center : [
    {
      lat: 34.68283945215458,
      lng: 133.89635209986955
    }
  ],
  zoom : 12,
  styles: [
    {
      "featureType": "landscape",
      "stylers": [
        {
            "hue": "#FFBB00"
        },
        {
            "saturation": 43.400000000000006
        },
        {
            "lightness": 37.599999999999994
        },
        {
            "gamma": 1
        }
      ]
    },
    {
      "featureType": "road.highway",
      "stylers": [
        {
            "hue": "#FFC200"
        },
        {
            "saturation": -61.8
        },
        {
            "lightness": 45.599999999999994
        },
        {
            "gamma": 1
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "stylers": [
        {
            "hue": "#FF0300"
        },
        {
            "saturation": -100
        },
        {
            "lightness": 51.19999999999999
        },
        {
            "gamma": 1
        }
      ]
    },
    {
      "featureType": "road.local",
      "stylers": [
        {
            "hue": "#FF0300"
        },
        {
            "saturation": -100
        },
        {
            "lightness": 52
        },
        {
            "gamma": 1
        }
      ]
    },
    {
      "featureType": "water",
      "stylers": [
        {
            "hue": "#0078FF"
        },
        {
            "saturation": -13.200000000000003
        },
        {
            "lightness": 2.4000000000000057
        },
        {
            "gamma": 1
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
            "hue": "#00FF6A"
        },
        {
            "saturation": -1.0989010989011234
        },
        {
            "lightness": 11.200000000000017
        },
        {
            "gamma": 1
        }
      ]
    }
  ],

  init : function(viewdetail) {
    // 地図の作成
    var mapLatLng = new google.maps.LatLng({lat: OAT.map.center[0]['lat'], lng: OAT.map.center[0]['lng']});
    OAT.map.map = new google.maps.Map(document.getElementById(OAT.map.mapID), { 
        center: mapLatLng, // 地図の中心を指定
        zoom: OAT.map.zoom, // 地図のズームを指定
        draggable: false
    });
    // スタイルの付与
    var colorName = 'MyColor';
    OAT.map.map.mapTypes.set(colorName, new google.maps.StyledMapType(OAT.map.styles, { name: colorName }));
    OAT.map.map.setMapTypeId(colorName);
    // マーカー毎の処理
    for (var i = 0; i < OAT.map.markerData.length; i++) {
        var markerLatLng = new google.maps.LatLng({lat: OAT.map.markerData[i]['lat'], lng: OAT.map.markerData[i]['lng']}); // 緯度経度のデータ作成
        OAT.map.marker[i] = new google.maps.Marker({ // マーカーの追加
            position: markerLatLng, // マーカーを立てる位置を指定
            map: OAT.map.map, // マーカーを立てる地図を指定
            icon: {
                url: OAT.map.markerData[i]['icon'],// マーカーの画像を変更
                scaledSize : new google.maps.Size(26, 42)
            }
        });
        OAT.map.infoWindow[i] = new google.maps.InfoWindow({ // 吹き出しの追加
            content: '<div class="name">' + OAT.map.markerData[i]['name'] + '</div><div class="link"><a href="javascript:void(0)" onclick="OAT.map.mapInfoWindowClick('+"'"+OAT.map.markerData[i]['url']+"'"+')">'+viewdetail+'</a></div>' // 吹き出しに表示する内容
        });
        OAT.map.markerEvent(i); // マーカーにクリックイベントを追加
    }
    OAT.map.mapToggleLock();
  },
  markerEvent : function(i) {
    OAT.map.marker[i].addListener('click', function() { // マーカーをクリックしたとき
      OAT.map.infoWindowClose();
      OAT.map.infoWindow[i].open(OAT.map.map, OAT.map.marker[i]); // 吹き出しの表示
      OAT.map.marker[i].setZIndex(2);
    });
  },
  infoWindowClose : function() {
    for (var i = 0; i < OAT.map.markerData.length; i++) {
      OAT.map.infoWindow[i].close();
      OAT.map.marker[0].setZIndex(1);
    }
  },


  //
  // infoWindow内の view detail をクリックした時に smoothscroll 発火
  //
  mapInfoWindowClick : function(anchor) {
    OAT._smoothScroll($(anchor));
  },

  //
  // 各記事のsee map をクリックしたときに 吹き出しを開いた状態にする
  //
  seeMapFromArticle : function(i) {
    i = i -1;
    OAT.map.infoWindowClose();
    OAT.map.infoWindow[i].open(OAT.map.map, OAT.map.marker[i]); // 吹き出しの表示

    OAT.map.marker[i].setZIndex(2);
    setTimeout( function() {
      // var mapLatLng = new google.maps.LatLng({lat: OAT.map.center[0]['lat'], lng: OAT.map.center[0]['lng']});
      // OAT.map.map.setCenter(mapLatLng);
      var opts = {
        zoom : 11,
      };
      OAT.map.map.setOptions(opts);
    }, 200);
  },

  //
  // マップロック機能
  //
  mapToggleLock : function() {
      // OAT.mapStopScroll();
    $('.js-toggle-draggable').on('click', function() {
      if($(this).hasClass('is-locked')) {
        OAT.map.toEnable();
        $(this).removeClass('is-locked');
      } else {
        OAT.map.toDisable();
        $(this).addClass('is-locked');
      }
    });
  },
  toEnable : function() {
    var opts = {
      draggable: true
    };
    OAT.map.map.setOptions(opts);
  },
  toDisable : function() {
    var opts = {
      draggable: false
    };
    OAT.map.map.setOptions(opts);
  }

  // getBounds : function() {
  // },

  // setBounds : function() {
  //   var minX = marker[0].getPosition().lng();
  //   var minY = marker[0].getPosition().lat();
  //   var maxX = marker[0].getPosition().lng();;
  //   var maxY = marker[0].getPosition().lat();;
  //   for(var i=0; i<10; i++){
  //   var lt = marker[i].getPosition().lat();
  //   var lg = marker[i].getPosition().lng();
  //   if (lg <= minX){ minX = lg; }
  //   if (lg > maxX){ maxX = lg; }
  //   if (lt <= minY){ minY = lt; }
  //   if (lt > maxY){ maxY = lt; }
  //   }
  //   var sw = new google.maps.LatLng(maxY, minX);
  //   var ne = new google.maps.LatLng(minY, maxX);
  //   var bounds = new google.maps.LatLngBounds(sw, ne);
  //   map.fitBounds(bounds);
  // },


}
OAT.pagetop = {

  ptTimer : null,
  ptShow : false,
  ptShowPx : OAT.wh - 100,
  scrollTop : 0,


  _onTouchMove : function(e) {
    // e.preventDefault();
    OAT.pagetop.scrollTop = $(window).scrollTop() ? $(window).scrollTop() : 0;
    // console.log(OAT.pagetop.scrollTop);
    if( !OAT.pagetop.ptShow && OAT.pagetop.ptShowPx < OAT.pagetop.scrollTop ) {
      // console.log("show");
      OAT.pagetop.ptShow = true;
      // $('.l-pagetop').stop().fadeIn(300);
      $('.l-pagetop').addClass('is-show');
    }
    if( OAT.pagetop.ptShow && OAT.pagetop.ptShowPx > OAT.pagetop.scrollTop ) {
      // console.log("hide");
      OAT.pagetop.ptShow = false;
      // $('.l-pagetop').stop().fadeOut(300);
      $('.l-pagetop').removeClass('is-show')
    }
  },

};